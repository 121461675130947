
const model = {
  image: {
    data: [
      {
      attributes: {
        name: "Test",
        url: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
      }
    },
  ]
  },
  publicAt: "Test",
  description: "",
  name: "Test",
  content: "Test",
  slug: ""
}

export default model;