const model = {
    CTA: {
        content: "",
        button : []
    },
    carousel: [],
    hero: {
        content: "",
        picture : ""
    },
    seo :{
        metaTitle: "",
    }
}
export default model