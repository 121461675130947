const model = {
    skills: [],
    features: [],
    hero: {
        content: "",
        picture : ""
    },
    seo :{
        metaTitle: "",
    }
}
export default model